<template>
  <div class="pane_container">
    <div class="box_description" style="margin-bottom: 20px;">
      <span class="box_span">自报数据</span>
    </div>
    <el-form ref="realform" :model="realform" label-width="160px" class="parameterForm_css">
      <el-form-item label="瞬时流量">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="累计水量">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="报警">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="状态">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="闸前水位">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="闸后水位">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="闸位">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="电机实时工作数据">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="工作状态">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="水位">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <el-form-item label="水深">
        <el-input v-model="realform.password"></el-input>
      </el-form-item>
      <div class="clear"></div>
      <el-form-item>
        <el-button  size="mini" type="primary" @click="onSubmit">自报</el-button>
        <el-button  size="mini">取消</el-button>
      </el-form-item>
    </el-form>
    
    
    
  </div>

</template>

<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  
  export default {
    name: 'kzgl',//控制管理
    components: {
      
    },
    props: {
      //设备类型
      // deviceType:[String, Object, Array],
      visible:{
        default:false
      },
      //是否是详情，详情进入不可修改
      isCheckDetail:{
        default:false
      },
    },
    directives: {
      
    },
    data() {
      return {
        deviceType:1,
        controlForm: {
          flow: '',//流量
          gatePosition: '',//闸位控制
        },
        options: [{
          value: '1',
          label: '低'
        }, {
          value: '2',
          label: '中'
        }, {
          value: '3',
          label: '高'
        },],
        realform:{
          password:'',
        },
      }
    },
    watch: {
      
    },
    computed: {
      
    },
    filters: {
      
    },
    created() {
      
    },
    mounted() {
      
    },
    methods: {
      //清空
      onClear(){
        this.$confirm(`确定要清空监测站历史数据单元吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          this.$message.success('清空成功');
        }).catch(() => {});
      },
      //急停
      onStop(){
        this.$confirm(`确定要闸门电机急停吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          cancelButtonClass: 'message-style',
          type: 'warning'
        }).then(() => {
          this.$message.success('急停成功');
        }).catch(() => {});
      },
      //流量控制
      onSetFlow(){
        this.$message.success('设置成功');
      },
      // 闸位控制
      onSetGatePosition(){
        this.$message.success('闸位控制成功');
      },
      //自报
      onSubmit(){
        this.$message.success('自报成功');
      },
    },
  }

</script>

<style lang="scss" scoped>
  .pane_container{
    background: #fff;
    padding: 10px;
    .box_description {
      .box_span{
        font-size: 14px;
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
    .parameterForm_css{
      padding: 0 5%;
      .el-form-item{
        width: 50%;
        float: left;
      }
    }
  }
</style>
